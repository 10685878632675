import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { Avatar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { colors } from '../constants/colors';
import logo from '../assets/logoText.png';
import gig from '../assets/gig.png';
import ltcu from '../assets/ltcu.png';
import thf from '../assets/thf.png';
import edsi from '../assets/edsiLogo.png';
import fib from '../assets/fibLogo.png';
import fmd from '../assets/fmdLogo.png';
import ciscodiag from '../assets/CISDI_logoWhite.png';
import wpcu from '../assets/WPCU_white.png';
import msufcu from '../assets/msufcu_white.png';
import wealthdynamx from '../assets/wealthdynamx_white.png';
import clientschoice from '../assets/clientschoice_white.png';
import accel from '../assets/AcellLogo.png';
import servicecu from '../assets/serviceCUlogo.png';
import history from '../services/history';
import UserFilters from './filters/userFilters';
import { CustomFilterCard } from './customFilterCard';

const drawerWidth = 320;

const PageContainer = styled.div`
  display: flex;
`;

const VerticalLine = styled.hr`
  width: 0;
  color: #fff;
  height: 20px;
  margin: 34px 20px;
`;

const HeaderGrid = styled(Grid)`
  flex-grow: 0;
`;

const InnerContainer = styled.div`
  display: contents;
`;

const StyledDrawer = styled(({ pathname, ...forwardProps }) => <Drawer {...forwardProps} />)`
  ${({ pathname }) => (pathname === '/dashboard' ? '' : `display: none;`)}
  && {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
  .MuiDrawer-paper {
    margin: 90px 0;
    max-height: calc(100vh - 90px);
    width: ${drawerWidth}px;
    background-color: ${colors.gray3};

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.gray2};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.gray1};
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  && {
    width: 35px;
    height: 35px;
    background-color: ${colors.blue5};
    font-weight: bold;
    font-size: 12px;
  }
`;

const NameText = styled(Typography)`
  && {
    color: ${colors.white};
    margin: 0 0 0 15px;
  }
`;

const PageContent = styled(Grid)`
  padding: 107px 0;
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 0 12px 0 12px;
  }

  &.MuiIconButton-root {
    :hover {
      background-color: transparent;
    }
  }
`;

interface PageProps {
  pageContent: any;
}

const BasePage: React.FunctionComponent<PageProps> = ({ pageContent }) => {
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const email = localStorage.getItem('email');
  const institutionId = localStorage.getItem('institutionId');
  const institutionName = localStorage.getItem('institutionName');

  const getLogo = (institutionId: string | null) => {
    switch (institutionName) {
      case 'Pocketnest':
        return null;
      case 'LakeTrustCreditUnion':
        return <img src={ltcu} width="100" height="30" alt={'ltcuLogo'} />;
      case 'GigMobile':
        return <img src={gig} width="198" height="30" alt={'gigLogo'} />;
      case 'TheHenryFord':
        return <img src={thf} width="115" height="60" alt={'thfLogo'} />;
      case 'EDSI':
        return <img src={edsi} width="198" height="30" alt={'edsiLogo'} />;
      case 'FIB':
        return <img src={fib} width="198" height="30" alt={'fibLogo'} />;
      case 'FINMD':
        return <img src={fmd} width="185" height="25" alt={'fmdLogo'} />;
      case 'CiscoDiagMembers':
          return <img src={ciscodiag} width="185" height="25" alt={'ciscodiag'} />;
      case 'WPCU':
            return <img src={wpcu} width="185" height="25" alt={'wpcu'} />;
      case 'MSUCreditUnion':
            return <img src={msufcu} width="130" height="40" alt={'msufcu'} />;
      case 'MSUCreditUnion-FinLife':
            return <img src={msufcu} width="130" height="40" alt={'msufcu'} />;
      case 'WealthDynamx':
            return <img src={wealthdynamx} width="130" height="40" alt={'wealthdynamx'} />;
      case 'ClientsChoice':
            return <img src={clientschoice} width="130" height="40" alt={'clientschoice'} />;
      case 'ServiceCU':
        return <img src={servicecu} width="130" height="40" alt={'servicecu'} />;
      case 'TheAccelGroup':
      case 'AccelInternal':
        return <img src={accel} width="130" height="40" alt={'accel'} />;
      default:
        return null;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <PageContainer>
      <CssBaseline />
      <AppBar position="fixed" style={{ background: colors.blue4 }}>
        <Toolbar>
          <InnerContainer>
            <img src={logo} width="150" height="30" alt={'logo'} />
            {getLogo(institutionId) ?<VerticalLine color={'white'}/> : null }
            {getLogo(institutionId)}
          </InnerContainer>
          <HeaderGrid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <StyledAvatar alt="avatar" src="">
                {firstName && lastName
                  ? firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase()
                  : 'PN'}
              </StyledAvatar>
            </Grid>
            <Grid item>
              <NameText variant="body2">{firstName && lastName ? firstName + ' ' + lastName : email}</NameText>
              <Grid />
            </Grid>
            <StyledIconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <ExpandMoreIcon fontSize="large" fontWeight="normal" />
            </StyledIconButton>
          </HeaderGrid>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {/*<MenuItem*/}
            {/*  onClick={() => {*/}
            {/*    console.log('Change Password');*/}
            {/*    handleClose();*/}
            {/*  }}*/}
            {/*  disabled*/}
            {/*>*/}
            {/*  Change Password*/}
            {/*</MenuItem>*/}
            {/*<MenuItem disabled onClick={() => console.log('Edit Profile')}>*/}
            {/*  Edit Profile*/}
            {/*</MenuItem>*/}
            {/*<MenuItem disabled onClick={() => console.log('Support')}>*/}
            {/*  Support*/}
            {/*</MenuItem>*/}
            {/*<MenuItem disabled onClick={() => console.log('FAQs')}>*/}
            {/*  FAQs*/}
            {/*</MenuItem>*/}
            <MenuItem
              onClick={() => {
                localStorage.clear();
                history.push('/');
                pageContent = null;
              }}
            >
              Log out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <StyledDrawer pathname={pathname} variant="permanent">
        <UserFilters />
        <CustomFilterCard />
      </StyledDrawer>
      <PageContent container justifyContent="center">
        {pageContent}
      </PageContent>
    </PageContainer>
  );
};

export default BasePage;
