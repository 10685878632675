// resources
import { useState, useRef } from 'react';

import * as React from 'react';
import { connect } from 'react-redux';

import { CardContent, Alert } from '@mui/material';
import { AppBar, Toolbar } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { AuthActionCreators } from '../store/authStore/actions';
import { LockOutlined, MailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthState } from '../store/authStore/state';
import logo from '../assets/logoText.png';
import { ApplicationState } from '../store';
import { colors } from '../constants/colors';
import IconButton from '@mui/material/IconButton';

const LoginCard = styled(Card)`
  && {
    width: 485px;
    height: 449px;
    text-align: center;
    padding: 0 0 0 0;
    margin-right: auto;
    margin-left: auto;
    background: #f9fafe;
    border-radius: 15px;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`;

const GridContainer = styled(Grid)`
  padding-top: 10vh;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
`;

const StyledCardContent = styled(CardContent)`
  line-height: 50px;
`;

const StyledTextField = styled(TextField)`
  width: 295px;
  height: 55px;
  && {
    background: #ffffff;
  }
`;

const StyledButton = styled(Button)`
  && {
    color: #ffffff;
    background-color: ${colors.blue4};
    border-radius: 72px;
    max-height: 39px;
    width: 212px;
    font-size: 16px;
    text-transform: none;
  }
`;

const CardHeader = styled.div`
  background: ${colors.blue4};
  height: 84px;
`;

const StyledImg = styled.img`
  padding-top: 21px;
`;

const StyledItem = styled(Grid)`
  padding: 0 12px 0 12px;
`;

type OwnProps = AuthState & typeof AuthActionCreators;

const LoginPage: React.FunctionComponent<OwnProps> = ({ signIn, authError }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const loginButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <PageContainer>
        <AppBar position="fixed"style={{ background: colors.blue4 }}>
            <Toolbar>
                <img src={logo} width="150" height="30"  alt="logo"/>
            </Toolbar>
        </AppBar>
      <GridContainer container justifyContent="center">
        <LoginCard>
          <CardHeader>
            <StyledImg src={logo} alt={'logo'} />
          </CardHeader>
          <StyledCardContent>
            {authError ? (
                <Alert severity="error">The email or password you entered is incorrect. Please try again.</Alert>
            ) : null}
            <StyledTextField
              id="email-input"
              inputRef={emailRef}
              placeholder="Email"
              type="email"
              name="email"
              autoComplete="email"
              autoCapitalize="false"
              margin="normal"
              variant="outlined"
              value={email}
              onChange={event => setEmail(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();

                  if (passwordRef.current?.value) {
                    loginButtonRef.current?.click();
                  } else {
                    passwordRef.current?.focus();
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              id="password-input"
              inputRef={passwordRef}
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              autoComplete="current-password"
              margin="normal"
              variant="outlined"
              value={password}
              onChange={event => setPassword(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  loginButtonRef.current?.click();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container justifyContent="center">
              <StyledItem item>
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
              </StyledItem>
              <StyledItem item>
                <Link to="./forgotpassword">Forgot password?</Link>
              </StyledItem>
            </Grid>
          </StyledCardContent>
          <div>
            <StyledButton ref={loginButtonRef} color={'primary'} onClick={() => signIn({ email, password })} variant="contained">
              Log In
            </StyledButton>
          </div>
        </LoginCard>
      </GridContainer>
    </PageContainer>
  );
};

export default connect(
  (state: ApplicationState) => state.auth,
  AuthActionCreators,
)(LoginPage as any);
