// resources
import {useState, useRef, useEffect} from 'react';

import * as React from 'react';
import {connect} from 'react-redux';

import { CardContent } from '@mui/material';
import {AppBar, Toolbar} from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import {MailOutlined} from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import { forgotPassword } from '../services/baseServices'
import logo from '../assets/logoText.png';
import {ApplicationState} from '../store';
import {colors} from '../constants/colors';
import {Link} from "react-router-dom";


const LoginCard = styled(Card)`
  && {
    width: 485px;
    height: 400px;
    text-align: center;
    padding: 0 0 0 0;
    margin-right: auto;
    margin-left: auto;
    background: #f9fafe;
    border-radius: 15px;
  }
`;

const StyledItem = styled(Grid)`
  padding: 15px 0px;
`;

const PageContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`;

const GridContainer = styled(Grid)`
  padding-top: 10vh;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
`;

const StyledCardContent = styled(CardContent)`
  line-height: 25px;
`;

const StyledTextField = styled(TextField)`
  width: 295px;
  height: 55px;

  && {
    background: #ffffff;
  }
`;

const StyledButton = styled(Button)`
  && {
    color: #ffffff;
    background-color: ${colors.blue4};
    border-radius: 72px;
    max-height: 39px;
    width: 212px;
    font-size: 16px;
    text-transform: none;
  }
`;

const CardHeader = styled.div`
  background: ${colors.blue4};
  height: 100px;
`;

const StyledImg = styled.img`
  padding-top: 21px;
`;

const StyledHeaderText = styled(Grid)`
  padding: 0 12px 0 12px;
  font-weight: bold;
  color: whitesmoke;
`;

const ForgotPasswordPage: React.FunctionComponent = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);

    const emailRef = useRef<HTMLInputElement>(null);
    const resetPasswordButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      // eslint-disable-next-line
      const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
      setIsValidEmail(emailRegex.test(email));
    }, [email]);

    return (
        <PageContainer>
            <AppBar position="fixed" style={{background: colors.blue4}}>
                <Toolbar>
                    <img src={logo} width="150" height="30" alt="logo"/>
                </Toolbar>
            </AppBar>
            <GridContainer container justifyContent="center">
                <LoginCard>
                    <CardHeader>
                        <StyledImg src={logo} alt={'logo'}/>
                        <StyledHeaderText>Forgot Password</StyledHeaderText>
                    </CardHeader>
                    <StyledCardContent>
                        Enter your email address and we'll send you an email with instructions to reset your password.
                    </StyledCardContent>
                    <StyledCardContent>
                        <StyledTextField
                            id="email-input"
                            inputRef={emailRef}
                            placeholder="Enter your email address"
                            type="email"
                            name="email"
                            autoComplete="email"
                            autoCapitalize="false"
                            margin="normal"
                            variant="outlined"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
              
                                if (isValidEmail) {
                                  resetPasswordButtonRef.current?.click();
                                }
                              }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MailOutlined/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </StyledCardContent>
                    <div>
                        <StyledButton ref={resetPasswordButtonRef} disabled={!isValidEmail} color={'primary'} onClick={() => forgotPassword({ email })} variant="contained">
                            Reset Password
                        </StyledButton>
                    </div>
                    <StyledItem item>
                        <Link to="/">Return to login</Link>
                    </StyledItem>
                </LoginCard>
            </GridContainer>
        </PageContainer>
    );
};

export default connect(
    (state: ApplicationState) => state.auth,
)(ForgotPasswordPage as any);
